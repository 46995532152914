import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import SimpleCrypto from "simple-crypto-js"

import * as keys from './constant/keys'

import logo_default from './file/logo_facepay.svg'

const Bound = styled.div`
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    position: relative;
    p{
        font-family: 'SF Pro Display';
        font-style: normal;
        margin:0;
    }
    #copyright{
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translate(-50%, 0);
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .login-container{
        display: grid;
        grid-template-rows: 123px 1fr;
        width: 360px;
        height: 488px;
        position: absolute;
        left: 50%;
        top: 173px;
        transform: translate(-50%, 0);
        background-color: #fff;
        border: 0.3px solid #BFBFBF;
        box-sizing: border-box;
        border-radius: 4px;
        #logo{
            position: absolute;
            top: -16px;
            left: 28px;
        }
        #top-content{
            padding: 66px 28px 0 28px;
            border-bottom: 0.3px solid #BFBFBF;
            p{
                font-weight: 600;
                font-size: 26px;
                line-height: 31px;
                color: #000000;
            }
        }
        #bottom-content{
            display: flex;
            flex-direction: column;
            padding: 40px 28px 0 28px;
            .title-login{
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #959595;
                margin-bottom: 6px;
            }
            .input-login{
                border: 0.4px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 4px;
                width: 304px;
                height: 46px;
                margin-bottom: 22px;
                padding: 0 18px;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.01em;
                ::-webkit-input-placeholder { 
                    color: #BFBFBF;
                }
                :-ms-input-placeholder {
                    color: #BFBFBF;
                }
                ::placeholder {
                    color: #BFBFBF;
                }
            }
            .str-err{
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #F03B3B;
            }
            .login-button{
                display: flex;
                outline: none;
                border: none;
                width: 304px;
                height: 46px;
                align-items: center;
                justify-content: center;
                background: #2D98F0;
                border-radius: 4px;
                position: absolute;
                left: 28px;
                bottom: 35px;
                cursor: pointer;
                p{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                }
            }
        }
    }
`

const Login = () => {
    let dataLogin = null
    const refEmail = useRef(null);
    const refPassword = useRef(null);
    const simpleCrypto = new SimpleCrypto(keys.secretKey)
    const titleWrongLogin = "Tài khoản không đúng"

    const [errContent, setErrContent] = useState("");
    fetch('account.json').then((r) => r.json())
    .then((data) =>{
        console.log(data)
        dataLogin = data
    })

    useEffect(() => {
        return () => {
            
        }
    }, [])

    const checkMail = (email) => {
        var filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
            return false;
        } else {
            return true;
        }
    };
    
    const _changeInput = (e, type) =>{
        e.preventDefault();
        if (type)
            setErrContent("");
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        const email = refEmail.current.value;
        const password = refPassword.current.value;
        const checkEmail = checkMail(email);
        if (!checkEmail || !email || !password) {
            setErrContent(titleWrongLogin);
            return;
        }
        _handleLogin(email, password)
    }

    const _handleLogin = (username, password) => {
        if(!dataLogin){
            setErrContent(titleWrongLogin);
            return
        }
        dataLogin.forEach(item => {
            if(item.username === username && item.password === password){
                localStorage.setItem(keys.USER_FACEPAY_DOCS, JSON.stringify({
                    username,
                    type: item.type,
                    token: simpleCrypto.encrypt(`${item.type}|${password}`)
                }))
                window.location.reload()
            }
        });
    }

    return (
        <Bound>
            <div className = 'login-container'>
                <img src={logo_default} id='logo' alt='logo' style={{height: '64px', width: '64px'}}/>
                <div id='top-content'>
                    <p>Facepay Integration Portal</p>
                </div>
                <div id='bottom-content'>
                    <form onSubmit={_handleSubmit}>
                        <p className='title-login'>Email Address</p>
                        <input className='input-login' type='text' placeholder='name@company.com'
                            onChange={(e) => _changeInput(e, "email")} ref={refEmail}/>
                        <p className='title-login'>Password</p>
                        <input className='input-login' type='password' placeholder='password'
                            onChange={(e) => _changeInput(e, "password")} ref={refPassword}/>
                        <p className='str-err'>{errContent}</p>
                        <button className='login-button' type='submit'><p>Login</p></button>
                    </form>
                </div>
            </div>
            <p id='copyright'> Copyright ® Wee Digital, 2020 </p>
        </Bound>
    )
}

export default React.memo(Login)