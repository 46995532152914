import React, { useEffect, useState } from 'react';
import { RedocStandalone, MediaContentModel } from 'redoc';
import SimpleCrypto from "simple-crypto-js"

import * as keys from './constant/keys'

import Login from './Login'

function App() {
  const simpleCrypto = new SimpleCrypto(keys.secretKey)
  
  const [fileAPI, setFileAPI] = useState('bankapi.yaml')
  const [isLoading, setIsLoading] = useState(true)
  const [isLogin, setIsLogin] = useState(false)

  const checkStore = () =>{
    const store = JSON.parse(localStorage.getItem(keys.USER_FACEPAY_DOCS))
    if(store){
      fetch('account.json').then((r) => r.json())
      .then((data) =>{
        data.forEach(item => {
          if(item.username === store.username){
            let temp = simpleCrypto.decrypt(store.token)
            let arr = temp.split('|')
            if(arr[0] !== item.type || arr[1] !== item.password){
              localStorage.removeItem(keys.USER_FACEPAY_DOCS)
            }
            else{
              if(store.type === 'bank'){
                setFileAPI('bankapi.yaml')
              }
              else{
                setFileAPI('merchantapi.yaml')
              }
              setIsLogin(true)
            }
          }
        });
        setIsLoading(false)
      })
    }
    else{
      if(isLogin)
        setIsLogin(false)
      if(isLoading)
        setIsLoading(false)
    }
    
  }
  checkStore()

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])

  const onStorageChange = () => {
    console.log('storage change')
    window.location.reload()
  }

  return (
    
    <React.Fragment>
    {
      isLoading ?
      <div></div>
      :
      !isLogin ?
      <Login/>
      :
      <RedocStandalone
      
      specUrl={fileAPI}
      options={{
        nativeScrollbars: true,
        pathInMiddlePanel: true,
        hideSingleRequestSampleTab: true,
        theme: { 
          colors: { 
            primary: { 
              main: '#000000' 
            } ,
            text: {
              primary: '#212121',
            },
            http: {
              get: '#6bbd5b',
              post: '#248fb2',
              put: '#9b708b',
              options: '#d3ca12',
              patch: '#e09d43',
              delete: '#e27a7a',
              basic: '#999',
              link: '#31bbb6',
              head: '#c167e4',
            },
          } ,
          typography: {
            fontSize: '14px',
            lineHeight: '1.5em',
            fontWeightRegular: 'normal',
            // fontWeightBold: 'bold',
            fontWeightLight: '300',
            // fontFamily: 'SF Pro Display, sans-serif',
            smoothing: 'antialiased',
            optimizeSpeed: true,
            headings: {
              // fontFamily: 'SF Pro Display, sans-serif',
              fontWeight: 'bold',
            },
            code: {
              // fontFamily: 'SF Pro Display, sans-serif',
            },
          },
          sidebar: {
            width: '234px',
            backgroundColor: '#F7F7F7',
            textColor: '#000000',
          },
          
        },
        
      }}
    />
    }
    </React.Fragment>
  );
}

export default App;
